<template>
  <div class="case">
    <div class="banner"><img src="../../assets/images/banner05.png"></div>
    <div class="tips-top" @click="onLayer">以下内容由专业保险经纪人提供，点击查看资格证</div>
    <div v-for="item in list">
      <div class="list">
        <div class="line">病情描述：<span style="margin: .1rem 0">{{item.bingqiangmiaoshu}}</span></div>
        <div class="line" v-if="item.fenqi">肿瘤分期：<span>{{item.fenqi}}</span></div>
        <div class="line" v-if="item.fenji">BI-RADS分级：<span>{{item.fenji}}</span></div>
      </div>
      <div class="center">
        <div class="title">{{item.title}}</div>
        <div class="tips">声明：本文章的内容仅供参考，不构成任何治疗方案和投资建议</div>
        <div v-html="item.center"></div>
        <div v-if="item.imgUrl">
          <div class="case-img" v-for="ite in item.imgUrl">
            <img :src="ite">
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 1.5rem"></div>
    <div class="btn" @click="getData">下一篇</div>
    <div class="layer-box" v-if="layerShow">
      <layer />
      <div class="close" @click="close"><van-icon name="close" color="#fff"  size="30" /></div>
    </div>
  </div>
</template>

<script>
import image1 from '../../../src/assets/images/image1.png'
import image2 from '../../../src/assets/images/image.png'
import image3 from '../../../src/assets/images/image2.png'
import image4 from '../../../src/assets/images/image3.png'
import layer from './layer'
export default {
  name: 'case',
  data() {
    return {
      layerShow:false,
      timer:null,
      count:0,
      type:"",
      list:[],
      value:[
          {
            type:'rga',
            title:"抗癌经验-复发不可怕",
            center:'<p style=" text-indent:.53rem;margin-bottom: .2rem;">2020年初，42岁的李女士无意中摸到自己的左侧乳房有个硬块，但由于工作较忙，没有及时到医院进行检查，半年后才在爱人的催促陪同下前往就近妇科医院就诊，医生触诊询问后进行了相关病理检查，确诊为乳腺癌，手术完成很顺利，只是化疗并不是那么的轻松，整个化疗结束后，李女士感觉自己的身体健康状况变化很大。</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">李女士家境一般，患病前也只是个公司普通职员，本以为遇上这种重大疾病要花费很多，但是在16年时，经朋友强烈推荐，磨不开面子的李女士购买了当时市面上热销的重疾产品，保额21万，年交保费6900元，确诊后的李女士将自己的实际情况反馈给保险公司，提交病历资料后，顺利得到了理赔款，这笔钱大大缓解了李女士一家的家庭经济压力。</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">康复期间的李女士了解了很多乳腺癌方面的知识，知道了乳腺癌侵袭性高、容易复发、预后差。但李女士不会因此放弃治疗，可是她也不确定自己如果复发，这个家的积蓄是否能撑住？</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;"> 知道保险“好处”的李女士，想继续投保，但被朋友拒绝：“早知道要让你多买点保额，现在你买不了了！”、“复发风险太大，保险公司会拒保！”... 患病的李女士发现自己被多家保险公司拒之门外，难道不是得病的人才更需要保险么？千难万难，难买一个“早知道”！</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">2020年年底，李女士无意间通过病友群看到一款乳果爱复发险，怀揣着激动心理的李女士，迅速联系了相关人员，了解该款产品是复星联合健康保险公司推出的一款专门针对乳腺癌术后复发的消费型保险，保额有10万/40万/150万三种可选，还有多种报销计划可选，而李女士是一期乳腺癌，符合投保条件！</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">42岁的李女士，根据自己家庭实际经济情况，选择了40万保额的计划二，年交保费：4262元，保障包含：</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">社保内自费用100%报销，限额20万元；</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">社保范围外自费费用80%赔付，限额10万元；</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">特定肿瘤用品费用100%报销，限额10万；</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">保费虽然不便宜，但是也在李女士接受的范围内，下定决心的李女士在专业人员的指导下，一步步操作完成：提交资料进行预核保、填写个人信息进行投保付款。最终顺利承保！</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">承保的一瞬间，李女士觉得自己心头一松，李女士坚信：“宁可终身不用，不可一日不备”。今后的李女士，一定可以更加安心的接受治疗，渡过难关。</p>\n' +
                '      <p style=" text-indent:.53rem;margin-bottom: .2rem;">保单号W87200******0，X女士2020.4.13日确诊乳腺癌，2020.11.18日投保，2021.10.26再次入院，确诊首次复发。2021.12.2向我司申请理赔，截至目前赔付的金额为：27340元，客户还在持续的治疗中，后续还能享受该产品的增值服务。</p>',
            imgUrl:[image1,image1,image1,image1],
            bingqiangmiaoshu:"2020.4.13日确诊乳腺癌，临床诊断为左乳癌cT1N0MO期，左腋窝前哨淋巴结活检未见癌转移，分子分型为LuminalBHer-2阴性。2021.10.26再次入院，确诊首次复发。",
            fenqi:" cT1N0MOI期"

          },
        {
          type:'fhxb',
          title:"防患于未然-乳腺癌",
          center:"<p style=' text-indent:.53rem;margin-bottom: .2rem;' >汪女士38岁，有一儿一女，是一位初中三年级的班主任，在工作中认真负责，教书育人，但长期承受工作压力的汪女士身体也出现了不适的情况，在单位安排的体检中发现乳腺有结节的情况，前往医院被确诊为乳腺结节BI-RADS：三级。</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>汪女士考虑到自身工作的压力及家庭经济情况，定时复查，在此期间也了解了一些保险，但均发现自己的情况无法承保，或对于乳腺疾病除外承保。汪女士对此有些顾虑，还想继续增加关于乳腺方面的保障。</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>后经朋友推荐，发现太平洋有一款粉红相伴个人乳腺癌医疗保险，对于患有乳腺疾病人群，如：乳腺小叶增生、乳腺结节3级及以下、乳腺囊肿、乳腺纤维腺瘤等符合条件均可投保。</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>汪女士立即按照专业人士建议，按照投保指引上传各项病历检查，最终投保“粉红相伴医疗保险 计划二”，免赔额0元，保障期间一年，年交保费335元。</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>若之后汪女士乳腺结节恶化，则可以申请保险理赔减轻经济风险，保障包含：</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>乳腺原位癌医疗费用保险金：保额5万元，免赔额0万元；</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>乳腺恶性肿瘤医疗费用保险金：保额50万元，免赔额1万元；</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>保障期间享受健康管理服务：包含健康咨询、健康促进和就医服务。</p>\n" +
              "      <p style=' text-indent:.53rem;margin-bottom: .2rem;'>汪女士现有保障包含：原有的其他商业保险（乳腺疾病除外）+粉红相伴医疗健康管理服务+以及乳腺结节诊疗方案，她对于自己现阶段的保险方案保障非常满意，可以不再过于担心医疗费用问题，心态上发生很多变化，能积极配合医生治疗，定期复诊，取得最优治疗效果。</p>",
          bingqiangmiaoshu:"患者工作压力大，自感身体不适，在单位体检中发现乳腺结节，前往医院被确诊为乳腺结节BI-RADS:三级。",
          fenji:"3级"
        }
      ]
    }
  },
   components: {
     layer
   },
  props: {

  },
  created() {

  },
   mounted() {
    this.type = this.$route.query.type
     this.list = this.value.filter(item => item.type=== this.$route.query.type)
  },
  methods: {
    getData(){
      this.list =[];
      if(this.type == 'rga'){
        this.list = this.value.filter(item => item.type=== 'fhxb')
        this.type = 'fhxb'
      }else {
        this.list = this.value.filter(item => item.type=== 'rga')
        this.type = 'rga'
      }

    },
    onLayer(){
      this.layerShow = true
      const TIME_COUNT = 3;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.layerShow = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
       }

    },
    close(){
      clearInterval(this.timer);
      this.layerShow = false
      this.timer = null;

    }
  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.case{
  width: 100%;
  height: 100%;
  background: #F3F4FC;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  font-size: .28rem;
  .banner{
    width: 100%;
    height: 2.32rem;
    img{
      width: 100%;
      height: auto;
    }
  }
  .list{
    padding: .15rem .3rem .3rem .3rem;
    color: #C4C4C4;
    background: #fff;
    margin-bottom: .3rem;
    .line{
      padding-bottom: .25rem;
    }
    span{
      float: right;
      color: #131313;
    }
  }
  .center{
    padding: .3rem;
    color: #8C8C8C;
    background: #fff;
  }
  .title{
    text-align: center;
    font-weight: 500;
    color: #131313;
  }
  .tips{
    text-align: center;
    color: #DAE1E7;
    font-size: .22rem;
    margin: .2rem 0 .4rem 0;
  }
  .btn{
    width: 100%;
    height: 1.12rem;
    background: #fff;
    position: fixed;
    bottom:0 ;
    left: 0;
    text-align: center;
    font-weight: 500;
    color: #1F64EC;
    line-height: 1.12rem;
    border-top: 1px solid rgba(151, 151, 151, 0.1);
  }
  .case-img{
    margin-bottom: .2rem;
    img{
      width: 100%;
      height: auto;
    }
  }
}
.tips-top{
  background: #fff;
  font-size: .24rem;
  padding: .15rem .3rem 0 .3rem;
  color: #1F64EC;
}
.layer-box{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
  background: rgba(0,0,0, 0.5);
}
.close{
  width: 100%;
  text-align: center;
  margin-top: .2rem;
}
</style>

