var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "case" },
    [
      _vm._m(0),
      _c("div", { staticClass: "tips-top", on: { click: _vm.onLayer } }, [
        _vm._v("以下内容由专业保险经纪人提供，点击查看资格证")
      ]),
      _vm._l(_vm.list, function(item) {
        return _c("div", [
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "line" }, [
              _vm._v("病情描述："),
              _c("span", { staticStyle: { margin: ".1rem 0" } }, [
                _vm._v(_vm._s(item.bingqiangmiaoshu))
              ])
            ]),
            item.fenqi
              ? _c("div", { staticClass: "line" }, [
                  _vm._v("肿瘤分期："),
                  _c("span", [_vm._v(_vm._s(item.fenqi))])
                ])
              : _vm._e(),
            item.fenji
              ? _c("div", { staticClass: "line" }, [
                  _vm._v("BI-RADS分级："),
                  _c("span", [_vm._v(_vm._s(item.fenji))])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "center" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
            _c("div", { staticClass: "tips" }, [
              _vm._v("声明：本文章的内容仅供参考，不构成任何治疗方案和投资建议")
            ]),
            _c("div", { domProps: { innerHTML: _vm._s(item.center) } }),
            item.imgUrl
              ? _c(
                  "div",
                  _vm._l(item.imgUrl, function(ite) {
                    return _c("div", { staticClass: "case-img" }, [
                      _c("img", { attrs: { src: ite } })
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        ])
      }),
      _c("div", { staticStyle: { width: "100%", height: "1.5rem" } }),
      _c("div", { staticClass: "btn", on: { click: _vm.getData } }, [
        _vm._v("下一篇")
      ]),
      _vm.layerShow
        ? _c(
            "div",
            { staticClass: "layer-box" },
            [
              _c("layer"),
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.close } },
                [
                  _c("van-icon", {
                    attrs: { name: "close", color: "#fff", size: "30" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner" }, [
      _c("img", { attrs: { src: require("../../assets/images/banner05.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }